import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Services from "../components/services"
import Contact from "../components/contact"

export default () => (
  <Layout>
    <SEO title="Services" />
    <div>
      <h1>
        Services
      </h1>
      <Services />
      <h2>
        Contact
      </h2>
      <p>
        If you have any questions at all, why not reach out?
      </p>      
      <Contact />
    </div>
  </Layout>
)